import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/politica-de-cookies",
  "title": "Politica de cookies"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Política de Cookies`}</h1>
    <p>{`En cumplimiento con lo dispuesto en el artículo 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, esta página web le informa, en esta sección, sobre la política de recogida y tratamiento de cookies.`}</p>
    <h2>{`¿Qué son las cookies?`}</h2>
    <p>{`Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.`}</p>
    <h2>{`¿Qué tipos de cookies utiliza esta página web?`}</h2>
    <p>{`Cookies de análisis - Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.`}</p>
    <h2>{`Cómo desactivar las Cookies`}</h2>
    <p>{`Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.`}</p>
    <p>{`A continuación puede acceder a la configuración de los navegadores webs más frecuentes para aceptar, instalar o desactivar las cookies:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
        }}>{`Firefox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://support.apple.com/kb/HT1677?viewlocale=es_ES"
        }}>{`Safari`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.google.com/chrome/answer/95647?hl=es"
        }}>{`Google Chrome`}</a></li>
    </ul>
    <h2>{`Cookies de Terceros`}</h2>
    <p>{`Esta página web utiliza servicios de terceros para recopilar información con fines estadísticos y de uso de la web.`}</p>
    <p>{`Listado de servicios de terceros que utilizan cookies.`}</p>
    <ul>
      <li parentName="ul">{`Typeform es un servicio de formularios de contacto prestado por Typeform S.L., una compañia ubicada en Barcelona con oficina principal en C/Bac de Roda, 163 (Local), 08018, Barcelona, España. En el siguiente enlace podrás saber mas sobre los datos utilizados en las `}<a parentName="li" {...{
          "href": "https://help.typeform.com/hc/es/articles/360029581691--Qu%C3%A9-pasa-con-mis-datos-"
        }}>{`cookies de Typeform`}</a>{`.`}</li>
      <li parentName="ul">{`Google Analytics es un servicio de analítica web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Encontrarás más información en: `}<a parentName="li" {...{
          "href": "https://analytics.google.com"
        }}>{`https://analytics.google.com`}</a>{`
Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar al Titular a analizar el uso que hacen los usuarios del sitio Web. La información que genera la cookie acerca del uso del sitio Web (incluyendo tu dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.`}</li>
    </ul>
    <p>{`Fecha de actualización: 23 de Febrero de 2020`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      