import React from "react"

import MainLayout from "../components/layouts/BlogLayout"
import SEO from "../components/seo"
import PoliticaDeCookies from "../legal/politica-de-cookies.mdx"

const NotFoundPage = () => (
  <MainLayout>
    <SEO title="Politica de cookies" />
    <PoliticaDeCookies />
  </MainLayout>
)

export default NotFoundPage
